import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { properties } from "../../content/Properties"
import InnerPropertyPage from "../../components/molecules/InnerPropertyPage"

const slug = `30-midlow-road-elizabeth-downs-sa-5113`

const post = properties.find(post => post.slug === slug) // Find the blog post by slug

const MidlowRoadElizabethDownsSa5113 = () => (
  <Layout>
    <Seo
      title="NDIS-Supported SDA Housing Vacancy in Elizabeth Downs"
      description="Secure your spot in NDIS-supported housing in Elizabeth Downs! Accessible, comfortable living awaits. Contact us now for a supportive lifestyle opportunity!"
    />

    <InnerPropertyPage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default MidlowRoadElizabethDownsSa5113
